@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Millik';
  src: url("./Assets/Fonts/millik-5056651/Millik.otf");
}
@font-face {
  font-family: 'General Sans';
  src: url('./Assets/Fonts/GeneralSans_Complete/Fonts/OTF/GeneralSans-Medium.otf');
  src: url('./Assets/Fonts/GeneralSans_Complete/Fonts/OTF/GeneralSans-Regular.otf');
  src: url('./Assets/Fonts/GeneralSans_Complete/Fonts/OTF/GeneralSans-Semibold.otf');
}
body {
  margin: 0;
  font-family: "General Sans",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Millik";
}

.body-bg {
  /* The image used */
  background-image: url("./Assets/images/ng.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   
  position: relative;
}
.faq-bg {
  /* The image used */
  background-image: url("./Assets/images/faq.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   
  position: relative;
}

@media (max-width: 640px) { 
  .faq-bg {
    background-image: url("./Assets/images/faqs.png");
  }

}